// Import Bootstrap Compass integration
//@import "bootstrap-compass";

// Import custom Bootstrap variables
//@import "bootstrap-variables";

// Import Bootstrap for Sass
//@import "bootstrap";
@import "./node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Bitter:400,400italic');
@import url('https://fonts.googleapis.com/css?family=Archivo+Black');
@import url('https://fonts.googleapis.com/css?family=Raleway');


$main-background-color:#d7d2b4;
$main-background-color-white:#ffffff;
$header-color-brown:#7e4713;
$header-color-orange:#e6730f;



body{
	background-color:$main-background-color-white;
}
/************** slide show ******************************/
.carousel-control.left {
	background-image:none;
}

.carousel-control.right {
	background-image:none;
}
.footer-text1{
	float: right;
	color: #000000;
}
.slide{
	background-image:url("../images/lg_bg2.jpg");
	/*background-repeat: repeat-x;
	min-height:200px;*/
}

.slide-image{
	/*min-height:230px;*/
	/*min-width:680px;*/
}
.carousel-inner > .item > img, .carousel-inner > .item > a > img {
	margin:0 auto;

}
.orange-button{
	background-color:$header-color-orange;
	color:#ffffff;

}
.slider-nav{
	font-weight:bold;
	font-size:45px;
	color:#000000;
}
.header{
	padding:20px;
}
.content{
	@extend .row;
	
	.header{
		font-family:"Archivo Black",sans-serif;
		font-size:32px;
		color:$header-color-brown;
		
	}

}
.content, .content p, .content .content-text p{
	text-align: left;
}
.content-text{
	color:$header-color-brown;
	font-szie:14px;
	padding:0 20px 20px 20px;
}
a {
	color: #337ab7;
	text-decoration: none;
}
a.back-to-top {
	display: none;
	width: 60px;
	height: 60px;
	text-indent: -9999px;
	position: fixed;
	z-index: 999;
	right: 20px;
	bottom: 20px;
	background-color: $header-color-orange ;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}
.hover{
	position:relative;
}
	
.bar{
	@extend .row;
	max-height:10px;
	background-color:#000000;
	
}
.mobile_only{

}

.not-mobile{
	display:block;
}

.work{
	@extend .row;
	
}

.work-box{
		@extend .col-lg-4, .col-md-4, .col-sm-12;
		
		img{
			max-width:100%;
			margin:0 0 20px 0;
		}
		
}

.work-overlay{
	display:none;
	max-width:400px;
	height:300px;
	width:400px;
	max-height:300px;
	
	background-image:url("../images/bg_50.png");
	background-repeat:repeat;
	z-index:100;
	position:absolute;
}
.work-image{
	z-index:10;
	position:relative;
}
.hidden-image{
		
	display:block;
}
.promo-text{
	color:#ffffff;
	font-weight:bold;
	text-align:center;
	font-size:20px;
	margin:100px 0 0 0;
}
.overlay-effect .work-box img {
display: block;
/* margin: 0 0 20px 0;*/
padding: 0;
max-width: 100%;
			  height: auto;
}

/*.overlay-effect .work-overlay {
	top: 0;
	left: 15px;
	right: 0;
	width: 100%;
	height: 0;
}


.overlay-effect .promo-text {
	top: 0;
	left: 15px;
	right: 0;
	width: 100%;
	height: 0;
}
.overlay-effect a.close-overlay {
	top: 0;
	right: 0;
	z-index: 100;
	width: 45px;
	height: 45px;
}*/


.overlay-effect .work-box.hover .work-overlay {
	height: 100%;
}

.overlay-effect .work-box.hover .promo-text {
	display: block;
	height: 100%;
}

.overlay-effect .work-box.hover a.close-overlay {
	display: block;
}

a.close-overlay {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	width: 45px;
	height: 45px;
	font-size: 20px;
	font-weight: 700;
	color: #fff;
	line-height: 45px;
	text-align: center;
	cursor: pointer;
}


a.close-overlay.hidden {
	display: none;
}

/**************** form styles***************************************/
.form-spacer{
	@extend .col-md-12;
}
.form-control{
	border-color:#000000;
	border:2px solid #000000;
	
}
.form-message{
	@extend .col-lg-12;
	
}
.contact{
	@extend .row;

}
.contact-box{
	@extend .col-lg-5, .col-md-5, .col-sm-5;
}


.contact-box2{
	@extend .col-lg-6, .col-md-6, .col-sm-6 ;
}

/***************** navigation *************************************/
.navbar-default {
    background-color: #ffffff;
    border-color: #ffffff;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    background-color:#8a551f;
    color: #ffffff;
}
.navbar{
	.logo{
		width:150px;
		margin:10px 20px 0 0;
	}
	a:link, a:visited{
		font-weight: bold;
		color:#000000;
	}
}
.navbar-nav {
	.nav-link a:link,a:visited{
		font-weight: bold;
		color: #000000;
	}
}
.nav{
	@extend .row;
	margin:10px 0 0 0;
	padding:0 0 10px 0;
	.logo{
		width:170px;
		padding:10px 0 10px 0;
	}
}
/********************** portfolio pages ***********************/
.port{
	display:none;
}
.portfolio-row {
	@extend .row;
	display: none;

}
.portfolio-mobile{
	@extend .col-lg-3, .col-md-3, .col-sm-12;
	font-size: 16px;
	color: #000000;
	text-align: left;

}
.portfolio-desktop{
	@extend .col-lg-6, .col-md-6, .col-sm-12 ;
}

.portfolio-desktop img {
	width: 90%;
	margin: 0 0 20px 0;
}

.portfolio-mobile img {
	width: 100%;
	margin: 0 0 20px 0;
	max-width: 450px;
}

.port-title {
	font-family: "Raleway", sans-serif;
	color: #e6730f;
	font-size: 22px;
	font-weight: bold;
}
#port-header{
	display:none;
}

/********************** footer *********************************/
.footer{
	@extend .row;
	
	background-color:#000000;
	.logo{
		width:120px;
		margin:20px 0 20px 20px;
	
	}
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
/* Styles */



}
@media only screen and (min-device-width : 576px) and (max-device-width : 764px) {
	/* Styles */

	.not-mobile {
		display: none;
	}
	.mobile-only {
		display: block;
	}

	.hidden-image {

		display: block;
	}

	.promo-text {

		margin: 60px 0 0 0;
	}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
	/* Styles */
	
	.not-mobile{
		display:none;
	}
	.mobile-only{
		display:block;
	}
	
	.hidden-image{
		
		display:block;
	}

	.promo-text{
	
		margin:60px 0 0 0;
}
	
}
